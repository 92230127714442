<template>
  <div class="mainPage">
    <Header />
    <div class="mainContainer">
      <div class="settingsMainBody">
        <div class="accountSettingCell" v-for="item in state.data" :key="`settingAccount${item}`" @click="openEditDialog(item)">
          <div class="icon">
            <IconsWorker :icon="item.icon" width="20" color="#000"/>
          </div>
          <div class="keyAndValue">
            <div class="key">{{ item.key }}</div>
            <div class="value">{{ item.value }}</div>
          </div>
          <div class="editLink">تعديل</div>
        </div>
      </div>
    </div>
  </div>

  <EditAccountLightBox :is-open="state.isEditLightBoxOpen" :on-done="onEditingDone"  :on-close="closeEditLightBox" :the-key="state.selectedItem" :int-value="state.currentValue"/>

</template>

<script>
import Header from "../components/Header";
import IconsWorker from "../components/IconsWorker";
import {onMounted, reactive, watch} from "vue";
import myAccountAsyncStore from "../store/myAccountAsyncStore";
import DuInput from "../components/DuInput";
import DuButton from "../components/DuButton";
import EditAccountLightBox from "../components/EditAccountLightBox";
export default {
  name: "MyAccount",
  components: {EditAccountLightBox, DuButton, DuInput, IconsWorker, Header},
  setup() {
    const state = reactive({
      data: [],
      selectedItem: null,
      isChangingSetting: false,
      isEditLightBoxOpen: false,
      formRefs: [],
      currentValue: '',
    });



    const buildData = () => {
      const storedData = myAccountAsyncStore.getters.getData;
      state.data = [
        { key: 'الاسم' , value : storedData.name, icon: 'idF', lightBoxTitle: 'تعدايل الاسم' , settingsKey: 'name' },
        { key: 'اسم المستخدم' , value : storedData.username, icon: 'userF',  lightBoxTitle: 'تعدايل اسم المستخدم' , settingsKey: 'username' },
        { key: 'البريد الإلكتروني' , value : storedData.email, icon: 'emailF',  lightBoxTitle: 'تعدايل البريد', settingsKey: 'email'  },
        { key: 'كلمة المرور' , value : "*********", icon: 'fingerPrintF',  lightBoxTitle: 'تغيير كلمة المرور' , settingsKey: 'password' },
      ];
    }

    watch(() => myAccountAsyncStore.getters.getData, () => {
      buildData();
    });

    onMounted(() => {
      buildData();
    });

    const openEditDialog = (item) => {
      state.selectedItem = item.settingsKey;
      state.currentValue = item.value;
      if(item.settingsKey === 'password') {
        state.currentValue = "";
      }
      state.isEditLightBoxOpen = true;
    }

    const onEditingDone = () => {
      state.isEditLightBoxOpen = false;
    }

    const closeEditLightBox = () => {
      state.isEditLightBoxOpen = false;
    }

    return {
      state,
      onEditingDone,
      openEditDialog,
      closeEditLightBox,
      buildData
    };
  }
}
</script>

<style scoped>
.mainPage{
  background: #eee;
  min-height: 100%;
}
.settingsMainBody{
  width: 80%;
  margin: 100px 10%;
  background: #fff;
  border-radius: 10px;
  box-shadow:  0 0 10px #ccc;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.accountSettingCell{
  background: #fff;
  padding: 20px 4%;
  width: available;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  gap: 10px;
  align-items: start;
  text-align: start;
  cursor: pointer;
}
.accountSettingCell:hover .editLink{
  display: block;
}
.accountSettingCell:hover{
  background: #fefefe;
}
.keyAndValue{
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
}
.accountSettingCell .key{
  color: #777;
  font-size: 13px;
  font-weight: bold;
}
.accountSettingCell .value{
  color: #000;
  font-size: 16px;
  margin-top: 2px;
}
.accountSettingCell .icon{
  border-radius: 100px;
  margin-top: 3px;
}
.accountSettingCell .editLink{
  color: #FF0029;
  font-weight: bold;
  display: none;
  font-size: 13px;
  margin-top: 2px;
}

.titleHeader .icon{
  cursor: pointer;
}
</style>