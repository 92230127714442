<template>
  <div class="editLightBox" v-if="isOpen">
    <div class="editBody">
      <div class="titleHeader">
        <span>{{ getInputProps().lightBoxTitle }}</span>
        <IconsWorker icon="close" width="30" class="icon" @click="closeSettingsDialog()"/>
      </div>
      <div class="inputArea">
        <DuInput
            :label="getInputProps().lable"
            :is-required="true"
            is-required-error-message=""
            :type="getInputProps().type"
            :type-error-message="getInputProps().typeError"
            :get-ref="(e) => state.formRefs.push(e)"
            @input="(e) => { state.newValue = e.value }"
        />
      </div>
      <div class="saveArea">
        <DuButton text="حفظ" @click="save()" />
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, watch} from "vue";
import myAccountAsyncStore from "../store/myAccountAsyncStore";
import AjaxWorker from "../helpers/AjaxWorker";
import FixedNotification from "./plugins/fixedNotifications/FixedNotification";
import DuInput from "./DuInput";
import DuButton from "./DuButton";
import IconsWorker from "./IconsWorker";

export default {
  name: "EditAccountLightBox",
  components: {IconsWorker, DuButton, DuInput},
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    lightBoxTitle: {
      type: String,
      default: '',
    },
    theKey: {
      type: String,
      default: '',
    },
    onDone: {
      type: Function,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    userId: {
      type: Number,
      default: null,
    },
    intValue: {
      default: '',
    },
  },
  setup(props) {

    const state = reactive({
      data: [],
      selectedItem: null,
      isChangingSetting: false,
      formRefs: [],
      newValue: '',
    });



    const save = () => {
      let isFormValid = true;
      state.formRefs.forEach((el) => {
        el.validateNow();
        if(!el.state.isValid) {
          isFormValid = false;
        }
      });
      if(isFormValid) {
        state.isChangingSetting = true;
        const params = {};
        params[props.theKey] = state.newValue;
        if(props.userId != null) {
          params.userId = props.userId;
        }
        new AjaxWorker().request('/transactions.php?action=updateData', params).then((res) => {
          console.log(res);
          state.isChangingSetting = false;
          if(res === "NOINTERNETCONNECTION") {
            FixedNotification.create({
              title: 'حدث خطاء أثناء الإتصال',
              decription: 'تأكد من إتصالك بالإنترنت',
              icon: 'wifiF',
              color: 'red',
              hideAfter: 3000
            });
          } else {
            if(res.state) {
              props.onDone();
              myAccountAsyncStore.dispatch('getMyData');
              FixedNotification.create({
                decription: 'تم الحفظ',
                icon: 'cloudTrueF',
                color: 'green',
                hideAfter: 3000
              });
            } else {
              let errorMessage = 'خطاء غير معلوم حاول مرة أخرى';
              let errorIcon = 'errorF';
              if(res.reason === "email_is_used_by_another_user") {
                errorMessage = "هذا البريد مستخدم مسبقًا";
                errorIcon = "emailF";
              } else if(res.reason === "password_is_invalid") {
                errorMessage = "كلمة المرور غير صحيحة";
                errorIcon = "roundLockF";
              } else if(res.reason === "username_is_used_by_another_user") {
                errorMessage = "اسم المستخدم مستعمل مسبقًا";
                errorIcon = "userF";
              } else if(res.reason === "invalid_username") {
                errorMessage = "اسم المستخدم غير صالح";
                errorIcon = "userF";
              }
              FixedNotification.create({
                decription: errorMessage,
                icon: errorIcon,
                color: 'red',
                hideAfter: 3000
              });
            }
          }
        });
      }
    }
    const closeSettingsDialog = () => {
      state.selectedItem = null;
      props.onClose();
    }

    const getInputProps = () => {
      let obj = {};
      if(props.theKey === 'username') {
        obj.lable = 'اسم المستخدم';
        obj.type = 'username';
        obj.typeError = 'اسم المستخدم غير صالح , يتكون اسم المستخدم من حروف و أرقام إنجليزية فقط';
        obj.parameter = 'username';
        obj.lightBoxTitle = 'تغيير اسم المستخدم';
      } else if(props.theKey === 'email') {
        obj.lable = 'البريد الإلكتروني';
        obj.typeError = 'هذا البريد غير صالح';
        obj.type = 'email';
        obj.parameter = 'email';
        obj.lightBoxTitle = 'تغيير البريد الإلكتروني';
      } else if(props.theKey === 'name') {
        obj.lable = 'الاسم';
        obj.type = 'name';
        obj.typeError = 'هذا الاسم غير صالح';
        obj.parameter = 'name';
        obj.lightBoxTitle = 'تغيير الاسم';
      } else if(props.theKey === 'password') {
        obj.lable = 'كلمة المرور الجديدة';
        obj.typeError = 'كلمة المرور غير صالحة';
        obj.type = 'password';
        obj.parameter = 'password';
        obj.lightBoxTitle = 'تغيير كلمة المرور';
      }
      return obj;
    }

    const getTitle = () => {
      let title = '';
      if(props.theKey === 'username') {
        title = 'تغيير اسم المستحدم';
      } else if(props.theKey === 'email') {
        title = 'تغيير البريد الإلكتروني';
      } else if(props.theKey === 'name') {
        title = 'تغيير الاسم';
      } else if(props.theKey === 'password') {
        title = 'تغيير كلمة المرور';
      }
      return title;
    }

    const initData = () => {
      if(props.intValue != '') {
        setTimeout(() => {
          state.formRefs.forEach((el) => {
            el.state.innerValue = props.intValue;
          });
        }, 500);
      }
    }

    watch(() => props.intValue, () => {
      initData();
    });

    onMounted(() => {
      initData();
    });

    return {
      getTitle,
      state,
      getInputProps,
      closeSettingsDialog,
      save,
    };
  }
}
</script>

<style scoped>

.editLightBox{
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.editBody{
  width: 30%;
  margin: 15% 35%;
  z-index: 100;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px #555;
  display: flex;
  flex-direction: column;
  text-align: start;
  position: absolute;
}

.titleHeader{
  width: available;
  padding: 20px 5%;
  font-size: 20px;
  color: #555;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  place-items: center;
}


.inputArea{
  width: available;
  padding: 20px 5%;
  font-size: 15px;
  color: #555;
}

.saveArea{
  width: available;
  padding: 10px 5%;
  box-shadow: 0px -5px 3px #eee;
  margin-top: 50px;
  display: flex;
  place-content: end;
}

@media only screen and (max-width: 600px) {
  .editBody{
    width: 99%;
    margin: auto 0.5%;
    bottom: 0;
  }
}
</style>